"use client";

import { useEffect, useState } from "react";

const Header = () => {
  const [marginTop, setMarginTop] = useState(250); // Default marginTop for larger screens
  const [padding, setPadding] = useState("40px 0"); // Default padding for larger screens

  // Effect to set marginTop and padding based on window size
  useEffect(() => {
    const updateLayout = () => {
      if (window.innerWidth < 768) {
        setMarginTop(260); // For smaller screens
        setPadding("60px 20px"); // More padding for smaller screens
      } else {
        setMarginTop(140); // For larger screens
        setPadding("40px 10px"); // Default padding for larger screens
      }
    };

    // Initial check
    updateLayout();

    // Add resize listener
    window.addEventListener("resize", updateLayout);
    return () => window.removeEventListener("resize", updateLayout); // Clean up on unmount
  }, []);

  return (
    <header style={{ ...styles.header, marginTop: `${marginTop}px`, padding }} role="banner">
      <h1 style={styles.title}>😄 Ultimate Dad Jokes Collection 😄</h1>
      <p style={styles.subtitle}>
        Welcome to the ultimate source of dad jokes on the internet! Whether you're looking for clean, cheesy humor, or just a classic dad pun to lighten the mood, our curated list has you covered. Perfect for dads, kids, and anyone who loves a good pun, our dad jokes are sure to get you smiling. From classic one-liners to cringe-worthy zingers, explore our jokes that never get old.
      </p>
    </header>
  );
};

const styles = {
  header: {
    textAlign: "center",
    backgroundColor: "#2c3e50",
    color: "white",
    borderRadius: "10px",
    marginBottom: "30px",
  },
  title: {
    fontSize: "3rem",
  },
  subtitle: {
    fontSize: "1.5rem",
  },
};

export default Header;
