"use client";

const JokeCard = ({ question, punchline }) => {
  return (
    <li style={styles.joke}>
      <span style={styles.jokeNumber}></span> {question}
      <div style={styles.punchline}>&ldquo;{punchline}&rdquo;</div>
    </li>
  );
};

const styles = {
  joke: {
    marginBottom: "20px",
    fontSize: "1.25rem",
  },
  jokeNumber: {
    color: "#3498db",
    fontWeight: "bold",
  },
  punchline: {
    color: "#e74c3c",
    fontStyle: "italic",
    marginTop: "5px",
  },
};

export default JokeCard;
