"use client";

import { useEffect, useState, useRef  } from "react";
import Header from "./components/Header";
import JokeCard from "./components/JokeCard";
// import { CSSProperties } from 'react';
import './styles.css'; 

const jokeCategories = {
  dadJokes: [
    {
      id: 1,
      question: "Why don't scientists trust atoms?",
      punchline: "Because they make up everything!",
    },
    {
      id: 2,
      question: "What do you call a bear with no teeth?",
      punchline: "A gummy bear!",
    },
    {
      id: 3,
      question: "Why don't eggs tell jokes?",
      punchline: "They'd crack each other up!",
    },
    {
      id: 4,
      question: "What do you call a sleeping bull?",
      punchline: "A bulldozer!",
    },
    {
      id: 5,
      question: "How do you make a tissue dance?",
      punchline: "Put a little boogie in it!",
    },
    {
      id: 6,
      question: "Why did the math book look so sad?",
      punchline: "Because it had too many problems!",
    },
    {
      id: 7,
      question: "What do you call a fake noodle?",
      punchline: "An impasta!",
    },
    {
      id: 8,
      question: "Why did the cookie go to the doctor?",
      punchline: "Because it was feeling crumbly!",
    },
    {
      id: 9,
      question: "What do you call a can opener that doesn't work?",
      punchline: "A can't opener!",
    },
    {
      id: 10,
      question: "Why did the golfer bring two pairs of pants?",
      punchline: "In case he got a hole in one!",
    },
    {
      id: 11,
      question: "What do you call a boomerang that doesn't come back?",
      punchline: "A stick!",
    },
    {
      id: 12,
      question: "Why did the scarecrow win an award?",
      punchline: "He was outstanding in his field!",
    },
    {
      id: 13,
      question: "What do you call a pig that does karate?",
      punchline: "A pork chop!",
    },
    {
      id: 14,
      question: "Why don't oysters donate to charity?",
      punchline: "Because they're shellfish!",
    },
    {
      id: 15,
      question: "What do you call a cow with no legs?",
      punchline: "Ground beef!",
    },
    {
      id: 16,
      question: "Why don't skeletons fight each other?",
      punchline: "They don't have the guts!",
    },
    {
      id: 17,
      question: "What do you call a dog magician?",
      punchline: "A labracadabrador!",
    },
    {
      id: 18,
      question: "Why did the bicycle fall over?",
      punchline: "Because it was two-tired!",
    },
    {
      id: 19,
      question: "What do you call a bear with no ears?",
      punchline: "B!",
    },
    {
      id: 20,
      question: "How do you make an octopus laugh?",
      punchline: "With ten-tickles!",
    },
    {
      id: 21,
      question: "Why did the duck cross the playground?",
      punchline: "To get to the other slide!",
    },
    {
      id: 22,
      question: "What do you call a fish wearing a bowtie?",
      punchline: "Sofishticated!",
    },
    {
      id: 23,
      question: "How does a penguin build its house?",
      punchline: "Igloos it together!",
    },
    {
      id: 24,
      question: "What do you call a sleeping dinosaur?",
      punchline: "A dino-snore!",
    },
    {
      id: 25,
      question: "Why did the gym close down?",
      punchline: "It just didn't work out!",
    },
    {
      id: 26,
      question: "What do you call a parade of rabbits hopping backwards?",
      punchline: "A receding hare-line!",
    },
    { id: 27, question: "How do trees get online?", punchline: "They log in!" },
    {
      id: 28,
      question: "Why don't eggs tell each other secrets?",
      punchline: "Because they'd crack up!",
    },
    {
      id: 29,
      question: "Why did the robot go back to school?",
      punchline: "To improve its motor skills!",
    },
    {
      id: 30,
      question: "What do you call a fly without wings?",
      punchline: "A walk!",
    },
    {
      id: 31,
      question: "What do you call a fish wearing a crown?",
      punchline: "King of the sea!",
    },
    {
      id: 32,
      question: "Why did the banana go to the doctor?",
      punchline: "It wasn't peeling well!",
    },
    {
      id: 33,
      question: "What do you call a snowman with a six-pack?",
      punchline: "An abdominal snowman!",
    },
    {
      id: 34,
      question: "Why did the picture go to jail?",
      punchline: "Because it was framed!",
    },
    {
      id: 35,
      question: "What do you call a bee that can't make up its mind?",
      punchline: "A maybe!",
    },
    {
      id: 36,
      question: "Why did the belt go to jail?",
      punchline: "For holding up a pair of pants!",
    },
    {
      id: 37,
      question: "Why did the computer go to the doctor?",
      punchline: "It had a virus!",
    },
    {
      id: 38,
      question: "What do you call a cat that bowls?",
      punchline: "An alley cat!",
    },
    {
      id: 39,
      question: "Why did the drum take a nap?",
      punchline: "It was beat!",
    },
    {
      id: 40,
      question: "What do you call a sheep with no legs?",
      punchline: "A cloud!",
    },
    {
      id: 41,
      question: "Why was the math book sad?",
      punchline: "Because it had too many problems!",
    },
    {
      id: 42,
      question: "How do you organize a space party?",
      punchline: "You planet!",
    },
    {
      id: 43,
      question: "What do you call a bear in the rain?",
      punchline: "A drizzly bear!",
    },
    {
      id: 44,
      question: "Why don't scientists trust stairs?",
      punchline: "Because they're always up to something!",
    },
    {
      id: 45,
      question: "What do you call a fake stone in Ireland?",
      punchline: "A sham rock!",
    },
    {
      id: 46,
      question: "Why did the scarecrow become a successful politician?",
      punchline: "He was outstanding in his field!",
    },
    {
      id: 47,
      question: "What do you call a dog that can tell time?",
      punchline: "A watch dog!",
    },
    {
      id: 48,
      question: "Why don't eggs tell each other jokes?",
      punchline: "They'd crack up!",
    },
    {
      id: 49,
      question: "What do you call a sleeping kangaroo?",
      punchline: "A pouch potato!",
    },
    {
      id: 50,
      question: "Why don't ants get sick?",
      punchline: "Because they have tiny ant-ibodies!",
    },
    {
      id: 51,
      question: "What do you call a bear with no socks?",
      punchline: "Bare-foot!",
    },
    {
      id: 52,
      question: "Why did the cookie go to the nurse?",
      punchline: "Because it was feeling crumbly!",
    },
    {
      id: 53,
      question: "What do you call a monkey in a minefield?",
      punchline: "A baboom!",
    },
    {
      id: 54,
      question: "Why don't oysters share their pearls?",
      punchline: "Because they're shellfish!",
    },
    {
      id: 55,
      question: "What do you call a pile of cats?",
      punchline: "A meowtain!",
    },
    {
      id: 56,
      question: "Why did the golfer wear two pairs of pants?",
      punchline: "In case he got a hole in one!",
    },
    {
      id: 57,
      question: "What do you call a dog on the beach in summer?",
      punchline: "A hot dog!",
    },
    {
      id: 58,
      question: "Why don't eggs make jokes?",
      punchline: "They'd crack each other up!",
    },
    {
      id: 59,
      question: "What do you call a sleeping bull?",
      punchline: "A bulldozer!",
    },
    {
      id: 60,
      question: "How do you make a tissue dance?",
      punchline: "You put a little boogie in it!",
    },
    {
      id: 61,
      question: "What do you call a bear with no teeth?",
      punchline: "A gummy bear!",
    },
    {
      id: 62,
      question: "Why did the bicycle fall over?",
      punchline: "Because it was two-tired!",
    },
    {
      id: 63,
      question: "What do you call a fish wearing a bowtie?",
      punchline: "So-fish-ticated!",
    },
    {
      id: 64,
      question: "Why don't skeletons fight each other?",
      punchline: "They don't have the guts!",
    },
    {
      id: 65,
      question: "What do you call a cow with no legs?",
      punchline: "Ground beef!",
    },
    {
      id: 66,
      question:
        "Why did the scarecrow become a successful motivational speaker?",
      punchline: "He was outstanding in his field!",
    },
    {
      id: 67,
      question: "What do you call a can opener that doesn't work?",
      punchline: "A can't opener!",
    },
    {
      id: 68,
      question: "Why did the math book look so sad?",
      punchline: "Because it had too many problems!",
    },
    {
      id: 69,
      question: "What do you call a boomerang that doesn't come back?",
      punchline: "A stick!",
    },
    {
      id: 70,
      question: "Why did the cookie go to the doctor?",
      punchline: "It was feeling crumbly!",
    },
    {
      id: 71,
      question: "What do you call a pig that does karate?",
      punchline: "A pork chop!",
    },
    {
      id: 72,
      question: "Why don't scientists trust atoms?",
      punchline: "Because they make up everything!",
    },
    {
      id: 73,
      question: "What do you call a dog magician?",
      punchline: "A labracadabrador!",
    },
    {
      id: 74,
      question: "How do you make an octopus laugh?",
      punchline: "With ten-tickles!",
    },
    {
      id: 75,
      question: "Why did the duck cross the playground?",
      punchline: "To get to the other slide!",
    },
    {
      id: 76,
      question: "How does a penguin build its house?",
      punchline: "Igloos it together!",
    },
    {
      id: 77,
      question: "What do you call a sleeping dinosaur?",
      punchline: "A dino-snore!",
    },
    {
      id: 78,
      question: "Why did the gym close down?",
      punchline: "It just didn't work out!",
    },
    {
      id: 79,
      question: "What do you call a parade of rabbits hopping backwards?",
      punchline: "A receding hare-line!",
    },
    {
      id: 80,
      question: "How do trees access the internet?",
      punchline: "They log in!",
    },
    {
      id: 81,
      question: "Why did the robot go back to school?",
      punchline: "To improve its motor skills!",
    },
    {
      id: 82,
      question: "What do you call a fly without wings?",
      punchline: "A walk!",
    },
    {
      id: 83,
      question: "Why did the banana go to the doctor?",
      punchline: "It wasn't peeling well!",
    },
    {
      id: 84,
      question: "What do you call a snowman with a six-pack?",
      punchline: "An abdominal snowman!",
    },
    {
      id: 85,
      question: "Why did the picture go to jail?",
      punchline: "Because it was framed!",
    },
    {
      id: 86,
      question: "What do you call a bee that can't make up its mind?",
      punchline: "A maybe!",
    },
    {
      id: 87,
      question: "Why did the belt go to jail?",
      punchline: "For holding up a pair of pants!",
    },
    {
      id: 88,
      question: "Why did the computer go to the doctor?",
      punchline: "It had a virus!",
    },
    {
      id: 89,
      question: "What do you call a cat that bowls?",
      punchline: "An alley cat!",
    },
    {
      id: 90,
      question: "Why did the drum take a nap?",
      punchline: "It was beat!",
    },
    {
      id: 91,
      question: "What do you call a bear in the rain?",
      punchline: "A drizzly bear!",
    },
    {
      id: 92,
      question: "Why don't scientists trust stairs?",
      punchline: "They're always up to something!",
    },
    {
      id: 93,
      question: "What do you call a fake stone in Ireland?",
      punchline: "A sham rock!",
    },
    {
      id: 94,
      question: "What do you call a dog that can tell time?",
      punchline: "A watch dog!",
    },
    {
      id: 95,
      question: "What do you call a sleeping kangaroo?",
      punchline: "A pouch potato!",
    },
    {
      id: 96,
      question: "Why don't ants get sick?",
      punchline: "They have tiny ant-ibodies!",
    },
    {
      id: 97,
      question: "What do you call a bear with no socks?",
      punchline: "Bare-foot!",
    },
    {
      id: 98,
      question: "What do you call a monkey in a minefield?",
      punchline: "A baboom!",
    },
    {
      id: 99,
      question: "What do you call a pile of cats?",
      punchline: "A meowtain!",
    },
    {
      id: 100,
      question: "What do you call a dog on the beach in summer?",
      punchline: "A hot dog!",
    },
    {
      id: 101,
      question: "What do you call a fish wearing a crown?",
      punchline: "King of the sea!",
    },
    {
      id: 102,
      question: "What do you call a deer with no eyes?",
      punchline: "No eye-deer!",
    },
    {
      id: 103,
      question: "Why did the strawberry cry?",
      punchline: "Because it was in a jam!",
    },
    {
      id: 104,
      question: "What do you call a dinosaur that crashes his car?",
      punchline: "Tyrannosaurus Wrecks!",
    },
    {
      id: 105,
      question: "How do you make a lemon drop?",
      punchline: "Just let it fall!",
    },
    {
      id: 106,
      question: "What do you call a pig that does karate?",
      punchline: "Pork chop!",
    },
    {
      id: 107,
      question: "Why don't eggs tell secrets?",
      punchline: "They'd crack up!",
    },
    {
      id: 108,
      question: "What do you call a sleeping T-Rex?",
      punchline: "A dino-snore!",
    },
    {
      id: 109,
      question: "How does a penguin build its house?",
      punchline: "Igloos it together!",
    },
    {
      id: 110,
      question: "What do you call a bear with no teeth?",
      punchline: "A gummy bear!",
    },
    {
      id: 111,
      question: "Why did the golfer bring two pairs of pants?",
      punchline: "In case he got a hole in one!",
    },
    {
      id: 112,
      question: "What do you call a fake noodle?",
      punchline: "An impasta!",
    },
    {
      id: 113,
      question: "Why did the cookie go to the doctor?",
      punchline: "It was feeling crumbly!",
    },
    {
      id: 114,
      question: "What do you call a can opener that doesn't work?",
      punchline: "A can't opener!",
    },
    {
      id: 115,
      question: "Why did the scarecrow win an award?",
      punchline: "He was outstanding in his field!",
    },
    {
      id: 116,
      question: "What do you call a boomerang that doesn't come back?",
      punchline: "A stick!",
    },
    {
      id: 117,
      question: "Why don't oysters donate to charity?",
      punchline: "Because they're shellfish!",
    },
    {
      id: 118,
      question: "What do you call a cow with no legs?",
      punchline: "Ground beef!",
    },
    {
      id: 119,
      question: "Why don't skeletons fight each other?",
      punchline: "They don't have the guts!",
    },
    {
      id: 120,
      question: "What do you call a dog magician?",
      punchline: "A labracadabrador!",
    },
    {
      id: 121,
      question: "Why did the bicycle fall over?",
      punchline: "Because it was two-tired!",
    },
    {
      id: 122,
      question: "What do you call a bear with no ears?",
      punchline: "B!",
    },
    {
      id: 123,
      question: "How do you make an octopus laugh?",
      punchline: "With ten-tickles!",
    },
    {
      id: 124,
      question: "Why did the duck cross the playground?",
      punchline: "To get to the other slide!",
    },
    {
      id: 125,
      question: "What do you call a fish wearing a bowtie?",
      punchline: "Sofishticated!",
    },
    {
      id: 126,
      question: "Why did the gym close down?",
      punchline: "It just didn't work out!",
    },
    {
      id: 127,
      question: "What do you call a parade of rabbits hopping backwards?",
      punchline: "A receding hare-line!",
    },
    {
      id: 128,
      question: "How do trees get online?",
      punchline: "They log in!",
    },
    {
      id: 129,
      question: "Why did the robot go back to school?",
      punchline: "To improve its motor skills!",
    },
    {
      id: 130,
      question: "What do you call a fly without wings?",
      punchline: "A walk!",
    },
    {
      id: 131,
      question: "Why did the banana go to the doctor?",
      punchline: "It wasn't peeling well!",
    },
    {
      id: 132,
      question: "What do you call a snowman with a six-pack?",
      punchline: "An abdominal snowman!",
    },
    {
      id: 133,
      question: "Why did the picture go to jail?",
      punchline: "Because it was framed!",
    },
    {
      id: 134,
      question: "What do you call a bee that can't make up its mind?",
      punchline: "A maybe!",
    },
    {
      id: 135,
      question: "Why did the belt go to jail?",
      punchline: "For holding up a pair of pants!",
    },
    {
      id: 136,
      question: "Why did the computer go to the doctor?",
      punchline: "It had a virus!",
    },
    {
      id: 137,
      question: "What do you call a cat that bowls?",
      punchline: "An alley cat!",
    },
    {
      id: 138,
      question: "Why did the drum take a nap?",
      punchline: "It was beat!",
    },
    {
      id: 139,
      question: "What do you call a sheep with no legs?",
      punchline: "A cloud!",
    },
    {
      id: 140,
      question: "How do you organize a space party?",
      punchline: "You planet!",
    },
    {
      id: 141,
      question: "What do you call a bear in the rain?",
      punchline: "A drizzly bear!",
    },
    {
      id: 142,
      question: "Why don't scientists trust stairs?",
      punchline: "Because they're always up to something!",
    },
    {
      id: 143,
      question: "What do you call a fake stone in Ireland?",
      punchline: "A sham rock!",
    },
    {
      id: 144,
      question: "What do you call a dog that can tell time?",
      punchline: "A watch dog!",
    },
    {
      id: 145,
      question: "What do you call a sleeping kangaroo?",
      punchline: "A pouch potato!",
    },
    {
      id: 146,
      question: "Why don't ants get sick?",
      punchline: "Because they have tiny ant-ibodies!",
    },
    {
      id: 147,
      question: "What do you call a bear with no socks?",
      punchline: "Bare-foot!",
    },
    {
      id: 148,
      question: "What do you call a monkey in a minefield?",
      punchline: "A baboom!",
    },
    {
      id: 149,
      question: "What do you call a pile of cats?",
      punchline: "A meowtain!",
    },
    {
      id: 150,
      question: "What do you call a dog on the beach in summer?",
      punchline: "A hot dog!",
    },
    {
      id: 151,
      question: "What do you call a fish wearing a crown?",
      punchline: "King of the sea!",
    },
    {
      id: 152,
      question: "Why was the math book sad?",
      punchline: "Because it had too many problems!",
    },
    {
      id: 153,
      question: "What do you call a bear in the winter?",
      punchline: "Brr!",
    },
    {
      id: 154,
      question: "Why did the cookie go to the party?",
      punchline: "He was feeling chip-per!",
    },
    {
      id: 155,
      question: "What do you call a sleeping bull?",
      punchline: "A bulldozer!",
    },
    {
      id: 156,
      question: "How do you make a tissue dance?",
      punchline: "You put a little boogie in it!",
    },
    {
      id: 157,
      question: "Why don't eggs make jokes?",
      punchline: "They'd crack each other up!",
    },
    {
      id: 158,
      question: "What do you call a dog with a fever?",
      punchline: "A hot dog!",
    },
    {
      id: 159,
      question:
        "Why did the scarecrow become a successful motivational speaker?",
      punchline: "He was outstanding in his field!",
    },
    {
      id: 160,
      question: "What do you call a boomerang that always comes back?",
      punchline: "Loyal!",
    },
    {
      id: 161,
      question: "Why did the tomato blush?",
      punchline: "Because it saw the salad dressing!",
    },
    {
      id: 162,
      question: "What do you call a fake spaghetti?",
      punchline: "An impasta!",
    },
    {
      id: 163,
      question: "Why did the cookie go to the nurse?",
      punchline: "Because it was feeling crumbly!",
    },
    {
      id: 164,
      question: "What do you call a can opener that works overtime?",
      punchline: "A can-do opener!",
    },
    {
      id: 165,
      question: "Why did the scarecrow become a successful politician?",
      punchline: "He knew how to talk straw-tegically!",
    },
    {
      id: 166,
      question: "What do you call a boomerang that flies really far?",
      punchline: "A boom-far-rang!",
    },
    {
      id: 167,
      question: "Why don't oysters share their pearls?",
      punchline: "Because they're shellfish!",
    },
    {
      id: 168,
      question: "What do you call a cow during an earthquake?",
      punchline: "A milk shake!",
    },
    {
      id: 169,
      question: "Why don't skeletons play in the band?",
      punchline: "They have no body to dance with!",
    },
    {
      id: 170,
      question: "What do you call a dog that does magic tricks?",
      punchline: "A Labracadabrador!",
    },
    {
      id: 171,
      question: "Why did the bicycle fall over?",
      punchline: "Because it was two-tired!",
    },
    {
      id: 172,
      question: "What do you call a bear with perfect hearing?",
      punchline: "All ears!",
    },
    {
      id: 173,
      question: "How do you make a squid laugh?",
      punchline: "With ten-tickles!",
    },
    {
      id: 174,
      question: "Why did the duck go to the chiropractor?",
      punchline: "To get its back quacked!",
    },
    {
      id: 175,
      question: "What do you call a fish wearing a tuxedo?",
      punchline: "So-fish-ticated!",
    },
    {
      id: 176,
      question: "Why did the gym close down?",
      punchline: "It just didn't work out!",
    },
    {
      id: 177,
      question: "What do you call a parade of wolves?",
      punchline: "A howl of a time!",
    },
    {
      id: 178,
      question: "How do flowers talk to each other?",
      punchline: "With flower power!",
    },
    {
      id: 179,
      question: "Why did the robot cross the road?",
      punchline: "To get to the other cyber-side!",
    },
    {
      id: 180,
      question: "What do you call a fly that's also a DJ?",
      punchline: "Pretty fly!",
    },
    {
      id: 181,
      question: "Why did the grape stop in the middle of the road?",
      punchline: "Because it ran out of juice!",
    },
    {
      id: 182,
      question: "What do you call a snowman on rollerblades?",
      punchline: "A chill roller!",
    },
    {
      id: 183,
      question: "Why did the painting go to jail?",
      punchline: "It was framed!",
    },
    {
      id: 184,
      question: "What do you call a bee having a bad hair day?",
      punchline: "A frisbee!",
    },
    {
      id: 185,
      question: "Why did the belt get arrested?",
      punchline: "For holding up a pair of pants!",
    },
    {
      id: 186,
      question: "Why did the smartphone go to the doctor?",
      punchline: "It had a virus!",
    },
    {
      id: 187,
      question: "What do you call a cat that's a bowling champion?",
      punchline: "An alley cat!",
    },
    {
      id: 188,
      question: "Why did the piano take a nap?",
      punchline: "It was keyed out!",
    },
    {
      id: 189,
      question: "What do you call a sheep floating in the air?",
      punchline: "A cloud!",
    },
    {
      id: 190,
      question: "How do astronauts throw a party?",
      punchline: "They planet!",
    },
    {
      id: 191,
      question: "What do you call a bear caught in a downpour?",
      punchline: "A drizzly bear!",
    },
    {
      id: 192,
      question: "Why don't scientists trust atoms?",
      punchline: "They make up everything!",
    },
    {
      id: 193,
      question: "What do you call a rock that never gets old?",
      punchline: "Peter Pan-ite!",
    },
    {
      id: 194,
      question: "What do you call a dog that can predict the future?",
      punchline: "A fortune-smeller!",
    },
    {
      id: 195,
      question: "What do you call a kangaroo that loves naps?",
      punchline: "A pouch potato!",
    },
    {
      id: 196,
      question: "Why don't ants need doctors?",
      punchline: "They have tiny ant-ibodies!",
    },
    {
      id: 197,
      question: "What do you call a bear that likes to dance?",
      punchline: "Groove-oso!",
    },
    {
      id: 198,
      question: "What do you call a monkey that loves explosions?",
      punchline: "A ba-boom!",
    },
    {
      id: 199,
      question: "What do you call a group of musical cats?",
      punchline: "A mew-sical!",
    },
    {
      id: 200,
      question: "What do you call a dog that loves the beach?",
      punchline: "A surf pup!",
    },
    {
      id: 201,
      question: "What do you call a fish that wears a crown underwater?",
      punchline: "Your Majesty!",
    },
    {
      id: 202,
      question: "Why did the pencil take a nap?",
      punchline: "It was feeling drawn out!",
    },
    {
      id: 203,
      question: "What do you call a bear that's good at math?",
      punchline: "Cal-cub-lator!",
    },
    {
      id: 204,
      question: "Why did the cookie go to the doctor?",
      punchline: "It was feeling crumbly!",
    },
    {
      id: 205,
      question: "What do you call a dinosaur that wears glasses?",
      punchline: "Dino-see-more!",
    },
    {
      id: 206,
      question: "Why did the balloon go to school?",
      punchline: "To get a-pop-ucation!",
    },
    {
      id: 207,
      question: "What do you call a dog that can do magic tricks?",
      punchline: "A Labracadabrador!",
    },
    {
      id: 208,
      question: "Why did the music note get in trouble?",
      punchline: "It was always making treble!",
    },
    {
      id: 209,
      question: "What do you call a sleeping pizza?",
      punchline: "A pizza dreams!",
    },
    {
      id: 210,
      question: "Why did the bicycle take a nap?",
      punchline: "It was two-tired!",
    },
    {
      id: 211,
      question: "What do you call a fish wearing a crown?",
      punchline: "Your Royal High-ness!",
    },
    {
      id: 212,
      question: "Why did the book go to the doctor?",
      punchline: "It had a bad case of loose leafs!",
    },
    {
      id: 213,
      question: "What do you call a bear with no teeth?",
      punchline: "A gummy bear!",
    },
    {
      id: 214,
      question: "Why did the playground bully steal the kid's lunch money?",
      punchline: "He was on a roll!",
    },
    {
      id: 215,
      question: "What do you call a dog that can tell time?",
      punchline: "A watch-dog!",
    },
    {
      id: 216,
      question: "Why did the cookie go to the party?",
      punchline: "It was feeling chip-per!",
    },
    {
      id: 217,
      question: "What do you call a snowman with a six-pack?",
      punchline: "An abdominal snowman!",
    },
    {
      id: 218,
      question: "Why did the picture go to jail?",
      punchline: "It was framed!",
    },
    {
      id: 219,
      question: "What do you call a bee having a bad hair day?",
      punchline: "A frisbee!",
    },
    {
      id: 220,
      question: "Why did the belt go to jail?",
      punchline: "For holding up a pair of pants!",
    },
    {
      id: 221,
      question: "What do you call a cat that's a bowling champion?",
      punchline: "An alley cat!",
    },
    {
      id: 222,
      question: "Why did the piano take a nap?",
      punchline: "It was feeling a bit off-key!",
    },
    {
      id: 223,
      question: "What do you call a sheep floating in the air?",
      punchline: "A cloud!",
    },
    {
      id: 224,
      question: "How do astronauts throw a party?",
      punchline: "They planet!",
    }, // Duplicate - keep first instance
    {
      id: 225,
      question: "What do you call a bear caught in a downpour?",
      punchline: "A drizzly bear!",
    }, // Duplicate - keep first instance
    {
      id: 226,
      question: "Why don't scientists trust atoms?",
      punchline: "They make up everything!",
    }, // Duplicate - keep first instance
    {
      id: 227,
      question: "What do you call a rock that never gets old?",
      punchline: "Peter Pan-ite!",
    }, // Duplicate - keep first instance
    {
      id: 228,
      question: "What do you call a dog that can predict the future?",
      punchline: "A fortune-smeller!",
    }, // Duplicate - keep first instance
    {
      id: 229,
      question: "What do you call a kangaroo that loves naps?",
      punchline: "A pouch potato!",
    }, // Duplicate - keep first instance
    {
      id: 230,
      question: "Why don't ants need doctors?",
      punchline: "They have tiny ant-ibodies!",
    }, // Duplicate - keep first instance
    {
      id: 231,
      question: "What do you call a bear that likes to dance?",
      punchline: "Groove-oso!",
    }, // Duplicate - keep first instance
    {
      id: 232,
      question: "Why did the egg have a day off?",
      punchline: "Because it was Fryday!",
    },
    {
      id: 233,
      question: "What do you call a criminal landing an airplane?",
      punchline: "Condescending!",
    },
    {
      id: 234,
      question: "Why did the coffee taste like dirt?",
      punchline: "Because it was ground just a few minutes ago!",
    },
    {
      id: 235,
      question: "What's the best present for a drummer?",
      punchline: "Broken drums - you can't beat them!",
    },
    {
      id: 236,
      question: "I made a song about tortillas once.",
      punchline: "Now it's more like a wrap!",
    },
    {
      id: 237,
      question: "What does a nosey pepper do?",
      punchline: "It gets jalapeño business!",
    },
    {
      id: 238,
      question: "Why do people who live in Greece hate waking up early?",
      punchline: "Because Dawn is tough on Greece!",
    },
    {
      id: 239,
      question:
        "I stayed up all night wondering where the sun went, and then it dawned on me!",
      punchline: "",
    },
    {
      id: 240,
      question: "What word becomes shorter when you add two letters to it?",
      punchline: "Short!",
    },
    {
      id: 241,
      question: "Why did the pillow go to school?",
      punchline: "It wanted to get its tassels!",
    },
    {
      id: 242,
      question:
        "What do you call a bear that's always connected to the internet?",
      punchline: "A cyber bear!",
    },
    {
      id: 243,
      question: "Why did the banana go to the doctor?",
      punchline: "It wasn't peeling well!",
    },
    {
      id: 244,
      question: "What do you call a dinosaur that loves pancakes?",
      punchline: "A tri-syrup-tops!",
    },
    {
      id: 245,
      question: "Why did the math book look so sad?",
      punchline: "Because it had too many problems!",
    },
    {
      id: 246,
      question: "What do you call a dog wearing headphones?",
      punchline: "A music hound!",
    },
    {
      id: 247,
      question:
        "Why did the scarecrow become a successful motivational speaker?",
      punchline: "He was outstanding in his field!",
    },
    {
      id: 248,
      question: "What do you call a boomerang that always comes back?",
      punchline: "Loyal!",
    },
    {
      id: 249,
      question: "Why did the tomato blush?",
      punchline: "Because it saw the salad dressing!",
    },
    {
      id: 250,
      question: "What do you call a fake spaghetti?",
      punchline: "An impasta!",
    },
    {
      id: 251,
      question: "Why did the cookie go to the nurse?",
      punchline: "Because it was feeling crumbly!",
    },
    {
      id: 252,
      question: "What do you call a can opener that works overtime?",
      punchline: "A can-do opener!",
    },
    {
      id: 253,
      question: "Why did the scarecrow become a successful politician?",
      punchline: "He knew how to talk straw-tegically!",
    },
    {
      id: 254,
      question: "What do you call a boomerang that flies really far?",
      punchline: "A boom-far-rang!",
    },
    {
      id: 255,
      question: "Why don't oysters share their pearls?",
      punchline: "Because they're shellfish!",
    },
    {
      id: 256,
      question: "What do you call a cow during an earthquake?",
      punchline: "A milk shake!",
    },
    {
      id: 257,
      question: "Why don't skeletons play in the band?",
      punchline: "They have no body to dance with!",
    },
    {
      id: 258,
      question: "What do you call a dog that does magic tricks?",
      punchline: "A Labracadabrador!",
    },
    {
      id: 259,
      question: "Why did the bicycle fall over?",
      punchline: "Because it was two-tired!",
    },
    {
      id: 260,
      question: "What do you call a bear with perfect hearing?",
      punchline: "All ears!",
    },
    {
      id: 261,
      question: "How do you make a squid laugh?",
      punchline: "With ten-tickles!",
    },
    {
      id: 262,
      question: "Why did the duck go to the chiropractor?",
      punchline: "To get its back quacked!",
    },
    {
      id: 263,
      question: "What do you call a fish wearing a tuxedo?",
      punchline: "So-fish-ticated!",
    },
    {
      id: 264,
      question: "Why did the gym close down?",
      punchline: "It just didn't work out!",
    },
    {
      id: 265,
      question: "What do you call a parade of wolves?",
      punchline: "A howl of a time!",
    },
    {
      id: 266,
      question: "How do flowers talk to each other?",
      punchline: "With flower power!",
    },
    {
      id: 267,
      question: "Why did the robot cross the road?",
      punchline: "To get to the other cyber-side!",
    },
    {
      id: 268,
      question: "What do you call a fly that's also a DJ?",
      punchline: "Pretty fly!",
    },
    {
      id: 269,
      question: "Why did the grape stop in the middle of the road?",
      punchline: "Because it ran out of juice!",
    },
    {
      id: 270,
      question: "What do you call a snowman on rollerblades?",
      punchline: "A chill roller!",
    },
    {
      id: 271,
      question: "Why did the painting go to jail?",
      punchline: "It was framed!",
    },
    {
      id: 272,
      question: "What do you call a bee having a bad hair day?",
      punchline: "A frisbee!",
    },
    {
      id: 273,
      question: "Why did the belt get arrested?",
      punchline: "For holding up a pair of pants!",
    },
    {
      id: 274,
      question: "Why did the smartphone go to the doctor?",
      punchline: "It had a virus!",
    },
    {
      id: 275,
      question: "What do you call a cat that's a bowling champion?",
      punchline: "An alley cat!",
    },
    {
      id: 276,
      question: "Why did the piano take a nap?",
      punchline: "It was keyed out!",
    },
    {
      id: 276,
      question: "What do you call a sheep floating in the air?",
      punchline: "A cloud!",
    },
    {
      id: 277,
      question: "How do astronauts throw a party?",
      punchline: "They planet!",
    }, // Duplicate - keep first instance
    {
      id: 278,
      question: "What do you call a bear caught in a downpour?",
      punchline: "A drizzly bear!",
    }, // Duplicate - keep first instance
    {
      id: 279,
      question: "Why don't scientists trust atoms?",
      punchline: "They make up everything!",
    }, // Duplicate - keep first instance
    {
      id: 280,
      question: "What do you call a rock that never gets old?",
      punchline: "Peter Pan-ite!",
    }, // Duplicate - keep first instance
    {
      id: 281,
      question: "What do you call a dog that can predict the future?",
      punchline: "A fortune-smeller!",
    }, // Duplicate - keep first instance
    {
      id: 282,
      question: "What do you call a kangaroo that loves naps?",
      punchline: "A pouch potato!",
    }, // Duplicate - keep first instance
    {
      id: 283,
      question: "Why don't ants need doctors?",
      punchline: "They have tiny ant-ibodies!",
    }, // Duplicate - keep first instance
    {
      id: 284,
      question: "What do you call a bear that likes to dance?",
      punchline: "Groove-oso!",
    }, // Duplicate - keep first instance
    {
      id: 285,
      question: "What do you call a monkey that loves explosions?",
      punchline: "A ba-boom!",
    }, // Duplicate - keep first instance
    {
      id: 286,
      question: "What do you call a group of musical cats?",
      punchline: "A mew-sical!",
    }, // Duplicate - keep first instance
    {
      id: 287,
      question: "What do you call a dog that loves the beach?",
      punchline: "A surf pup!",
    }, // Duplicate - keep first instance
    {
      id: 288,
      question: "What do you call a fish that wears a crown underwater?",
      punchline: "Your Majesty!",
    }, // Duplicate - keep first instance
    {
      id: 289,
      question: "Why did the cookie go to school?",
      punchline: "To become a wafer!",
    },
    {
      id: 290,
      question: "What do you call a bear that's always on time?",
      punchline: "A clock-bear!",
    },
    {
      id: 291,
      question: "Why did the balloon go to the party?",
      punchline: "To pop in!",
    },
    {
      id: 292,
      question: "What do you call a dinosaur that loves to garden?",
      punchline: "A ptero-dactyl!",
    },
    {
      id: 293,
      question: "Why did the pencil refuse to write?",
      punchline: "It was pointless!",
    },
    {
      id: 294,
      question: "What do you call a dog that can do backflips?",
      punchline: "A flip-flop!",
    },
    {
      id: 295,
      question: "Why did the music note go to the doctor?",
      punchline: "It had rhythm-itis!",
    },
    {
      id: 296,
      question: "What do you call a pizza that tells jokes?",
      punchline: "A comedy slice!",
    },
    {
      id: 297,
      question: "Why did the bicycle go to bed early?",
      punchline: "It was wheely tired!",
    },
    {
      id: 298,
      question: "What do you call a fish that wears a bow tie?",
      punchline: "Sofishticated!",
    },
    {
      id: 299,
      question: "Why did the book join the police?",
      punchline: "To keep the pages in order!",
    },
    {
      id: 300,
      question: "What do you call a bear on a trampoline?",
      punchline: "A jump-bear!",
    },
  ],
  darkHumourJokes: [
    {
      id: 1,
      question: "Why don’t graveyards ever get overcrowded?",
      punchline: "Because people are dying to get in!",
    },
  ],
  racistJokes: [
    {
      id: 1,
      question: "Racist joke?",
      punchline: "Not appropriate to display.",
    },
  ],
  christmasJokes: [
    {
      id: 1,
      question: "What do snowmen eat for breakfast?",
      punchline: "Frosted flakes!",
    },
  ],
  yoMamaJokes: [
    {
      id: 1,
      question: "Yo mama is so fat...",
      punchline: "When she steps on a scale, it says 'To be continued...'",
    },
  ],
  deezNutsJokes: [
    {
      id: 1,
      question: "What did the tree say to the squirrel?",
      punchline: "Deez nuts!",
    },
  ],
  fatJokes: [
    {
      id: 1,
      question: "Why did the fat guy bring a ladder to the bar?",
      punchline: "He heard the drinks were on the house.",
    },
  ],
  rizzJokes: [
    {
      id: 1,
      question: "What's the most attractive letter?",
      punchline: "Rizzpect the R!",
    },
  ],
};

export default function Home() {
  const [selectedCategory, setSelectedCategory] = useState("dadJokes");
  const [visibleCount, setVisibleCount] = useState(25);
  const [isLoading, setIsLoading] = useState(false);
  const [showNav, setShowNav] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const sentinelRef = useRef(null);

  const loadMoreJokes = () => {
    setIsLoading(true);
    setTimeout(() => {
      setVisibleCount((prevCount) => prevCount + 25);
      setIsLoading(false);
    }, 500);
  };

  // Scroll handler with throttle for better performance
  useEffect(() => {
    let isThrottled = false;

    const handleScroll = () => {
      if (isThrottled) return;

      isThrottled = true;
      setTimeout(() => {
        isThrottled = false;
      }, 100); // Throttle delay

      if (window.scrollY > lastScrollY) {
        setShowNav(false); // Hide nav on scroll down
      } else {
        setShowNav(true); // Show nav on scroll up
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreJokes();
        }
      },
      { rootMargin: "200px", threshold: 0.1 }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, []);

  return (
    <div style={combinedStyles.container}>
      <nav style={{ ...combinedStyles.nav, transform: showNav ? "translateY(0)" : "translateY(-100%)" }}>
        <ul style={combinedStyles.menu}>
          {Object.keys(jokeCategories).map((category) => (
            <li key={category} style={combinedStyles.menuItem}>
              <a
                href="#"
                style={{
                  ...combinedStyles.menuLink,
                  ...(category === selectedCategory ? combinedStyles.activeMenuLink : {}),
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedCategory(category);
                  setVisibleCount(25);
                }}
              >
                {category
                  .replace(/([A-Z])/g, " $1")
                  .replace(/^./, (str) => str.toUpperCase())}
              </a>
            </li>
          ))}
        </ul>
      </nav>

      <Header />

      <div
        style={
          selectedCategory === "dadJokes" ? combinedStyles.jokeBackground : {}
        }
      >
        <div style={combinedStyles.content}>
          <ul style={combinedStyles.list}>
            {jokeCategories[selectedCategory].slice(0, visibleCount).map((joke) => (
              <JokeCard
                key={joke.id}
                jokeNumber={joke.id}
                question={joke.question}
                punchline={joke.punchline}
              />
            ))}
          </ul>

          <div ref={sentinelRef} style={combinedStyles.sentinel} />

          {isLoading && <div style={combinedStyles.loadingMessage}>Loading more...</div>}
        </div>
      </div>
    </div>
  );
}

// Styles
const combinedStyles = {
  container: {
    maxWidth: "800px",
    margin: "0 auto",
    padding: "0 20px",
  },
  nav: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: "white",
    zIndex: 1000,
    padding: "0px 0",
    transition: "transform 0.3s ease-in-out", // Smooth slide in/out effect
    overflowX: "auto", // Enable horizontal scrolling
    whiteSpace: "nowrap", // Prevent items from wrapping
    width: "100%", // Make sure nav takes up the full width of the screen
  },
  menu: {
    listStyleType: "none",
    padding: "0px 0px",
    display: "flex",
    justifyContent: "flex-start", // Align the menu items to the left
    flexWrap: "nowrap", // Prevent wrapping of menu items
    textAlign: "center", // Center text inside the menu items
  },
  menuItem: {
    margin: "0 15px", // Add margin for spacing between items
  },
  menuLink: {
    backgroundColor: "white",
    color: "black",
    padding: "15px 10px",
    textDecoration: "none",
    borderRadius: "5px",
    transition: "background-color 0.3s",
    whiteSpace: "nowrap",
  },
  activeMenuLink: {
    backgroundColor: "#2c3e50", // Highlight color for active link
    color: "#fff", // Change text color for active link
    fontWeight: "bold",
  },
  content: {
    marginTop: "80px",
  },
  list: {
    listStyleType: "disc",
    paddingLeft: "20px",
  },
  sentinel: {
    height: "1px",
  },
  loadingMessage: {
    textAlign: "center",
    marginTop: "20px",
    color: "#555",
    fontSize: "1.2em",
    fontWeight: "bold",
  },
  
};
